@tailwind base; 
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply  text-primary font-secondary bg-white;
    /* overflow-hidden */
  }
  .h1 {
    @apply text-[54px] lg:text-[84px] font-primary font-bold capitalize leading-[120%] tracking-[-0.05em] mb-2;
  }
  .section {
    @apply h-screen w-screen;
  }
  .btn {
    @apply py-[18px] px-[50px] h-[66px] flex items-center justify-center text-base uppercase font-secondary font-semibold bg-primary text-white;
  }
  
  /* .images-container {
    max-width: 1200px;
    margin: 2rem auto;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
    gap: 1rem;
  }

  .image {
    width: 100%;
    height: 20rem;
    cursor: pointer;
  }

  .image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
  } */
}